<script>
import Vue from 'vue';

export default {
  name: 'DocumentationArticle',
  async beforeRouteEnter(to, from, next) {
    const slug = to.params.slug;
    const article = await Vue.ovReq.get('article/getOne?slug=' + slug);
    next((vm) => {
      vm.article = article;
      return vm;
    });
  },
  watch: {
    $route: {
      async handler(to, from) {
        const slug = to.params.slug;
        if (this.article.slug !== slug) {
          const article = await Vue.ovReq.get('article/getOne?slug=' + slug);
          this.article = article;
        }
      },
    },
  },
  components: {},
  computed: {},
  data() {
    return {
      article: {},
    };
  },
  methods: {},
};
</script>

<template lang="pug">
.article-container
  h1.display-1 {{ article.title }}
  div(v-html="article.content")
  .nav-buttons.d-flex.justify-content-between
    router-link.prev-page(:to="{name:'article', params: {slug:'some-other-page'}}")
      i.la.la-angle-double-left.mr-1
      | PREV PAGE NAME
    router-link.next-page(:to="{name:'article', params: {slug:'some-other-page'}}")
      | NEXT PAGE NAME
      i.la.la-angle-double-right.ml-1

</template>
